<template>
  <div class="wrapper">
      <el-container>
  <el-header height="auto">
        <Header />
      <Header2 />
  </el-header>

     <el-main>
			<router-view />
            <div class="flexCode">
                <el-tooltip placement="top" class="toolTip">
                    <div slot="content">
                        <img class="qrcode-img qrcode-img1" :src="qrcode1" alt="千电照明"> 
                        <img class="qrcode-img" :src="qrcode2" alt="千电照明">
                    </div>
                     <el-button type="primary" circle>
                         <i class="iconfont icon-erweima"></i>
                     </el-button>
                </el-tooltip>
            </div>
             
      </el-main>
    
    <el-footer>
      <div class="foot">
          <div class="con displayRow">
               <div class="left">
               <div class="tipText"><span class="labelText">温馨提示:</span><span>商品问题扫码联系客服咨询</span></div>
        <div class="tipText"><span class="labelText">订  购:</span><span>扫码直接订购</span></div>
        <div class="tipText displayRow">
          <span class="labelText">服  务:</span>
        <div class="serveText"><i class="el-icon-success"></i><span>正品保障</span></div>
        <div class="serveText"><i class="el-icon-success"></i><span>安全支付</span></div>
        <div class="serveText"><i class="el-icon-success"></i><span>闪电发货</span></div>
        <div class="serveText"><i class="el-icon-success"></i><span>售后无忧</span></div>
        </div>
         <div class="tipText"><span class="labelText">联系电话:</span><span>0760-22344935</span></div>
          <div class="tipText">
               <img :src="icp" alt="" class="icp">
               <a href="http://beian.miit.gov.cn/" target="_blank" class="icpText">粤ICP备2021131395号</a>
          </div>
          </div>
          <div class="right displayRow">
            <img class="qrcode-img qrcode-img1" :src="qrcode1" alt="千电照明"> 
            <img class="qrcode-img" :src="qrcode2" alt="千电照明">
          </div>
          </div>
      </div>
  </el-footer>
</el-container>
  </div>
</template>

<script>
import Header2 from '@/components/layOut/Header2'
import Header from '@/components/layOut/Header'
export default {
    
    data(){
        return{
            searchVal:'',
            cateData:{},
             qrcode1:require('@/assets/image/code1.jpg'),
            qrcode2:require('@/assets/image/code2.jpg'),
            icp:require('@/assets/image/icp.png'),
        }
    },
    components:{
        Header,
        Header2,
    },
    methods:{
    },
    mounted(){

    }

}
</script>

<style scoped>
.search-btn{
    margin-left: 5px;
}
.flexCode{
    position: fixed;
    right:0;
    top:40%;
   padding:20px;
     /* box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);
    border-radius: 10px 0 0 10px; */
}
.toolTip{
    display: block;
    margin-bottom: 20px;
}
.qrcode-img{
    width:120px;
    height:120px;
    display: block;
}
.qrcode-img1{
    padding-bottom: 20px;
}
.right .qrcode-img1{
    padding-right:50px;
    padding-bottom: 0;
}
.icon-erweima{
    color:#fff;
    font-size: 18px;
}
.foot{
    padding:20px 0;
    margin:0 20px;
    color:#eee;
    background-color: #25374b;
}
.con{
    width:1200px;
    margin:0 auto;
    justify-content: space-between;
}
.icp{
    width:20px;
    height: 20px;
    vertical-align: middle;
    margin-right:5px;
}
.icpText{
    color:#eee;
    text-decoration: none;
    font-size: 14px;
}
.tipText{
  font-size: 13px;
  padding-top:12px;
}
.labelText{
  width:70px;
  display:inline-block;
}
.serveText{
  margin-right: 15px;
}
.el-icon-success{
  color:#0388dd;
  margin-right: 5px;
  font-size: 16px;
}

</style>