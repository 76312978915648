<template>
  <div class="nav">
    <el-menu
      router
      :default-active="activeIndex" 
      class="el-menu-demo navList"
      mode="horizontal"
      active-text-color="#0388DD"
      background-color="#25374b"
      text-color="#fff"
    >
   
    <el-menu-item index="HomeData">首页</el-menu-item>
    <el-menu-item index="MyOrder">我的订单</el-menu-item>
    <el-menu-item index="MyAddress">收货地址</el-menu-item>
     </el-menu>
  </div>
</template>

<script>
export default {
  data() {
    return {
      logo: require("@/assets/image/logo.jpg"),
      activeIndex: "",
    };
  },
  watch: {
       $route () {
      this.setCurrentRoute()
    }
  },
  methods: {
        setCurrentRoute(){
    this.activeIndex = this.$route.name;
  },
  
  },
  created() {
      this.setCurrentRoute();
  },
};
</script>

<style scoped>
.nav{
background-color:#25374b;
}
.navList{
    width: 1200px;
    margin-left: auto;
    margin-right: auto;
    /* background-color: #545c64!important; */
}
</style>